import usePrefix from '@/hooks/usePrefix';
import { googleMapsApiKey } from '@/utils/googleMaps';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { useMobxStores } from '@stores/index';
import {
  getAirportLocalStorage,
  isServer,
  setAirportLocalStorage,
} from '@utils/isServer';
import { AutoComplete as AutoCompleteAntd, Button, Input, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { IAirportValue, ICityValue, ISearch } from '../types';
import { DatePicker } from './DatePicker';
import { EnvironmentFilled } from './Icons';
import { modalType } from './Modal';
import SearchRangePicker from './SearchRangePicker';
import RangePickerWithTime from './RangePickerWithTime';

const { RangePicker } = DatePicker;
const Libraries: any = ['geometry', 'places'];

function SearchBC({
  searchType,
  airports,
  title,
  selectedAirport,
  checkin,
  checkout,
  filterType,
  deviceType,
}: ISearch): ReactElement {
  const { searchStore } = useMobxStores(),
    router = useRouter(),
    prefix = usePrefix(),
    [dateRange, setDaterange] = useState({
      checkIn: checkin
        ? checkin
        : searchType === 'airport'
        ? dayjs()
            .add(1, 'day')
            .add(30 - (dayjs().minute() % 30), 'minute')
            .startOf('minute')
            .format()
        : dayjs()
            .add(30 - (dayjs().minute() % 30), 'minute')
            .startOf('minute')
            .format(),
      checkOut: checkout
        ? checkout
        : searchType === 'cruise'
        ? dayjs()
            .add(5, 'day')
            .add(30 - (dayjs().minute() % 30), 'minute')
            .startOf('minute')
            .format()
        : searchType === 'airport'
        ? dayjs()
            .add(8, 'day')
            .add(30 - (dayjs().minute() % 30), 'minute')
            .startOf('minute')
            .format()
        : dayjs()
            .add(30 - (dayjs().minute() % 30), 'minute')
            .startOf('minute')
            .add(3, 'hour')
            .format(),
    }),
    [airportValue, setAirportValue] = useState<IAirportValue>(), //airport
    [cityAutocomplete, setCityAutocomplete] = useState(null),
    [cityValue, setCityValue] = useState<ICityValue>(), //city
    autocompleteAirportOptions = airports?.result
      ?.filter((x) => filterType == 'all' || x.airport_display_type == 0)
      .map((airport) => {
        return {
          value: `${airport.airport_initials} - ${airport.airport_name}`,
          id: airport.airport_id,
          airportinitials: airport.airport_initials,
          all_search: `${airport.airport_initials} - ${airport.airport_name}- ${airport.airport_city}`,
        };
      });
  const autocompleteCruiseOptions = airports?.result
    ?.filter((x) => x.airport_display_type == 2)
    .map((airport) => {
      return {
        value: `${airport.airport_initials} - ${airport.airport_name}`,
        id: airport.airport_id,
        airportinitials: airport.airport_initials,
        lat: airport.airport_latitude,
        lng: airport.airport_longitude,
        all_search: `${airport.airport_initials} - ${airport.airport_name}- ${airport.airport_city}`,
      };
    });

  const [calendarView, setCalendarView] = useState<[Dayjs, Dayjs]>([
    checkin ? dayjs(checkin) : dayjs(),
    checkout
      ? dayjs(checkout)
      : searchType === 'cruise'
      ? dayjs().add(5, 'day')
      : searchType === 'airport'
      ? dayjs().add(7, 'day')
      : dayjs().add(1, 'day'),
  ]);
  const calendarChange = (a) => {
    if (a && a[0]?.isAfter(calendarView[1])) {
      setDaterange({ checkIn: a[0], checkOut: a[0].add(1, 'day') });
      setCalendarView([a[0], a[0].add(1, 'day')]);
    } else {
      setDaterange({ checkIn: a[0], checkOut: a[1] });
    }
  };

  function handleAutocompleteSelect({
    _value,
    option,
  }: {
    _value;
    option;
  }): void {
    setAirportLocalStorage(searchType, {
      value: option.value,
      id: option.id,
      airportinitials: option.airportinitials,
    });
    setAirportValue({
      value: option.value,
      id: option.id,
      airportinitials: option.airportinitials,
    });
    if (!isServer && router.pathname.includes('search')) {
      sessionStorage.setItem(
        'airport',
        JSON.stringify({
          value: option.value,
          id: option.id,
          airportinitials: option.airportinitials,
        })
      );
    }
  }

  function handleFilterAutocomplete({
    inputValue,
    option,
  }: {
    inputValue;
    option;
  }): boolean {
    return (
      option.all_search.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    );
  }

  function disabledDate(current): boolean {
    return current && current < dayjs().startOf('day');
  }

  function handleSearchValidations(): boolean {
    if (searchType === 'airport' || searchType === 'cruise') {
      if (airportValue?.value === null || airportValue?.value === '') {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'What is the Airport?',
        });
        return false;
      }
    } else {
      if (
        typeof cityValue?.formatted_address === 'undefined' ||
        cityValue.formatted_address === ''
      ) {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'Please inform a valid US place or address.',
        });
        return false;
      }
    }

    if (dateRange.checkIn === '' || dateRange.checkOut === '') {
      modalType({
        type: 'error',
        title: 'Alert',
        content: 'What is the Start and End date?',
      });

      return false;
    }
    if (dayjs(dateRange.checkIn).isSame(dateRange.checkOut)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkIn
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }

    if (dayjs(dateRange.checkOut).isBefore(dateRange.checkIn)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkOut
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }
  }

  function handleOnPlaceChanged() {
    if (cityAutocomplete) {
      const { formatted_address, geometry, html_attributions }: ICityValue =
        cityAutocomplete.getPlace();
      sessionStorage.setItem(
        'city',
        JSON.stringify({
          formatted_address,
          geometry,
          html_attributions,
        })
      );
      setCityValue({
        formatted_address,
        geometry,
        html_attributions,
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  function handleAirportCityRedirect(): void {
    handleAirportCityRedirectWithParameter(dateRange);
  }
  function handleAirportCityRedirectWithParameter(dRange): void {
    let routerUrl = '';
    const query = {};

    if (searchType === 'airport' || searchType === 'cruise') {
      routerUrl = prefix + '/reservation/search';
      Object.assign(query, {
        initials: airportValue.airportinitials,
        airport: airportValue.value,
        _$i: !isServer && window.btoa(airportValue?.id?.toString()),
      });
    } else if (searchType === 'city') {
      Object.assign(query, {
        city: cityValue.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
      routerUrl = prefix + '/reservation/citysearch';
      router.push({
        pathname: routerUrl,
        query: Object.assign(query, {
          checkin: dayjs(dRange.checkIn).format('YYYY-MM-DDTHH:mm'),
          checkout: dayjs(dRange.checkOut).format('YYYY-MM-DDTHH:mm'),
        }),
      });
      return;
    }
    router.push({
      pathname: routerUrl,
      query: Object.assign(query, {
        checkin: dayjs(dRange.checkIn).format('YYYY-MM-DDTHH:mm'),
        checkout: dayjs(dRange.checkOut).format('YYYY-MM-DDTHH:mm'),
      }),
    });
  }

  function handleAutoCompleteAirportChange(): void {
    !isServer && sessionStorage.removeItem('airport');
    setAirportValue({
      value: '',
      id: null,
      airportinitials: '',
    });
  }

  function handleAutoCompleteCityChange(e): void {
    setCityValue(e.target.value);
    !isServer && sessionStorage.removeItem('city');
  }

  function handleRangePickerOnChange(dateStrings): void {
    console.log('dateString', dateStrings);
    const [checkIn, checkOut] = dateStrings;
    const newRangeDate = { checkIn, checkOut };
    setDaterange(newRangeDate);
    setCalendarView([dayjs(checkIn), dayjs(checkOut)]);
    handleAirportCityRedirectWithParameter(newRangeDate);
  }

  function handleMobileDateOnChange({ type, date }: { type; date }): void {
    if (type === 'checkin') {
      const newCheckin = dayjs(date, 'MM/DD/YYYY');
      const newCheckout =
        searchType === 'cruise'
          ? newCheckin.add(5, 'day').format()
          : searchType === 'airport'
          ? newCheckin.add(7, 'day').format()
          : newCheckin.add(1, 'day').format();
      setDaterange({ checkIn: date, checkOut: newCheckout });
    } else {
      setDaterange({ ...dateRange, checkOut: date });
    }
  }

  useEffect(() => {
    if (
      (searchType === 'airport' || searchType === 'cruise') &&
      !selectedAirport
    ) {
      setAirportValue({
        value: router.query?.airport?.toString() || null,
        id: router.query._$i
          ? !isServer && Number(window.atob(router.query?._$i?.toString()))
          : null,
        airportinitials: router.query?.initials?.toString() || null,
      });
    }
    if (selectedAirport) {
      const data = JSON.parse(selectedAirport);
      setAirportValue({
        value: data?.value || null,
        id: Number(data?.id) || null,
        airportinitials: data?.airportinitials || null,
      });
    }
  }, [router.query, searchType, selectedAirport]);

  return (
    <div className="flex flex-col mb-2">
      <p className="font-bold my-2">Destination</p>
      <Tooltip
        title={
          deviceType == 'mobile' ? '' : 'Which airport are you departing from?'
        }
        overlayStyle={{ maxWidth: '300px' }}
        overlayClassName="search-tooltip"
      >
        <div className="md:bg-white rounded-md border border-gray-300 flex flex-auto flex-wrap items-center justify-start h-10 w-full mb-4 bg-white">
          {(searchType === 'airport' || searchType === 'cruise') && (
            <AutoCompleteAntd
              className="w-full border-white hover:border-white"
              options={
                searchType === 'airport'
                  ? autocompleteAirportOptions
                  : autocompleteCruiseOptions
              }
              filterOption={(inputValue, option) =>
                handleFilterAutocomplete({ inputValue, option })
              }
              defaultValue={
                router.query?.airport
                  ? router.query?.airport?.toString()
                  : selectedAirport
                  ? JSON.parse(selectedAirport)?.value
                  : getAirportLocalStorage(
                      searchType,
                      setAirportValue,
                      airportValue
                    )
              }
              onSelect={(value, option) =>
                handleAutocompleteSelect({ _value: value, option })
              }
              disabled={router.pathname.includes('parking')}
            >
              <Input
                data-test-id="airport"
                bordered={false}
                prefix={<EnvironmentFilled />}
                placeholder="Where do you want to park?"
                className="border-none ring-0 hover:border-white px-2 focus:outline-none input-search-airport-city"
                onChange={handleAutoCompleteAirportChange}
              />
            </AutoCompleteAntd>
          )}

          {searchType === 'city' && (
            <LoadScriptNext
              googleMapsApiKey={googleMapsApiKey}
              libraries={Libraries}
              onLoad={() => searchStore.setIsLoadedGoogle(true)}
            >
              <Autocomplete
                onLoad={(autocomplete) => setCityAutocomplete(autocomplete)}
                onPlaceChanged={handleOnPlaceChanged}
                restrictions={{ country: 'us' }}
                fields={['geometry.location', 'formatted_address']}
                className="w-full border-white hover:border-white px-0"
              >
                <div className="flex align-center overflow-hidden input-search-airport-city px-2">
                  <span className="flex items-center mr-1">
                    <EnvironmentFilled />
                  </span>
                  <input
                    data-test-id="address"
                    placeholder="Enter a city or address"
                    className="border-white hover:border-white w-full focus:outline-none flex flex-grow-1 search-placeholder-color bg-white"
                    defaultValue={
                      router.query?.city
                        ? router.query?.city?.toString()
                        : selectedAirport
                        ? JSON.parse(selectedAirport)?.value
                        : null
                    }
                    type="search"
                  />
                </div>
              </Autocomplete>
            </LoadScriptNext>
          )}
        </div>
      </Tooltip>
      <div className={`${'flex flex-row pt-4 md:pt-0 mb-0 md:mb-2'}`}>
        <p className="flex-1 font-bold">Start Date</p>
        <p className="flex-1 font-bold ml-4">End Date</p>
      </div>
      <RangePickerWithTime
        checkin={checkin}
        checkout={checkout}
        isCity={searchType == 'city'}
        onChange={handleRangePickerOnChange}
        isHome
        deviceType={deviceType}
      />
      <Button
        size="large"
        data-test-id="park_now"
        type="primary"
        block
        className="rounded-full text-base font-semibold hover:bg-opacity-75 w-full self-center md:mt-4"
        onClick={() => {
          if (handleSearchValidations() === false) return false;
          handleAirportCityRedirect();
        }}
      >
        {router.pathname.includes('search') ? 'Search' : 'Find a spot'}
      </Button>
    </div>
  );
}

export default observer(SearchBC);
